import { useEffect } from 'react';

function SupremeRedVans(){
    useEffect(()=>{
        const script = document.createElement("script");
    
        script.src = "/scripts/supreme-red-vans.js";
        script.async = true;
    
        document.body.appendChild(script);
      },[])
    return (
        <div className="App">
            <header className="App-header">
                <div id='product-component-1633139152553'></div>
            </header>
        </div>
        
    )
}

export default SupremeRedVans;