import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';

import logo from './sg-logo.png';
import './App.css';

import { FullPage, Slide } from 'react-full-page';

import ReactPageScroller from 'react-page-scroller';


import SG001 from './Products/SG001';
import SupremeRedVans from './Products/SupremeRedVans';

// import { MdNavigateBefore, MdNavigateNext, MdLens } from "react-icons/md";

function App() {

  const [currentPage, setCurrentPage] = useState()
  const [pageNumbers, setPageNumbers] = useState()

  useEffect(() => {
    getPageNumber()
  }, [])

  const handlePageChange = number => {
    console.log('num',number.target.attributes.eventKey.value)
    setCurrentPage(Number(number.target.attributes.eventKey.value));
  }

  const handleBeforePageChange = number => {
    console.log(number);
  };

  const getPageNumber = () => {
    const pageNumbersA = [];

    for (let i = 1; i <= 3; i++) {
      pageNumbersA.push(
        <span style={{ padding: '10px', fontSize: '50px'}} key={i} eventKey={i - 1} onClick={e => handlePageChange(e)} value="value__">
          .
        </span>,
      );
    }
    setPageNumbers(pageNumbersA);
  }
  

  return (
    <>
   
      <ReactPageScroller
          // pageOnChange={handlePageChange}
          onBeforePageScroll={handleBeforePageChange}
          customPageNumber={currentPage}>
            <div className="App"><header className="App-header"><img src={logo} className="App-logo" alt="logo" /></header></div>
            <SG001 />
            <SupremeRedVans />
      </ReactPageScroller>
      <div style={{left: '50%',padding: '10px',position: 'fixed',transform: 'translateX(-50%)',bottom: 0, color: 'white'}}>{pageNumbers}</div>

    </>
      
  );
}

export default App;

